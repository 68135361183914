import React from 'react';
import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/goodto.css';

loadCSS('goodto-site');

export const GoodtoSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default GoodtoSiteWrapper;
